import React, { memo } from "react";
import { useSelector } from "react-redux";

import {
    Box,
} from '@mui/material';

import BuildCircleIcon from '@mui/icons-material/BuildCircle';

import { Search, ClarificationsList } from "features/pages";

import {
    selectCurrentSymptom,
} from "features/pages/pagesSlice";

const ClarificationsComponent = () => {
    const currentSymptom = useSelector(selectCurrentSymptom);

    return <>
        <Box sx={{ fontWeight: 600, mb: 3, ml: 2, mr: 2 }} component={"span"}>{currentSymptom?.name}</Box>

        <ClarificationsList type="Localization" />
        <Box sx={{ mb: 4 }}>
            <ClarificationsList type="Details" />
        </Box>

        <Box sx={{ mb: 4 }}>
            <Search
                item={currentSymptom}
                type="Localization"
                inputColor="#aa904129"
                label={"Укажите локализацию симптома"}
                itemIcon={<BuildCircleIcon sx={{ color: 'text.secondary' }} />}
                resultItemProps={{
                    sx: { textTransform: "lowercase" }
                }}
            />
        </Box>
        <Search
            item={currentSymptom}
            showLevel={true}
            type="Details"
            inputColor="#ff000029"
            label={"Укажите характер симптома"}
            itemIcon={<BuildCircleIcon sx={{ color: 'text.secondary' }} />}
            resultItemProps={{
                sx: { textTransform: "lowercase" }
            }}
        />
    </>;
}

export const Clarifications = memo(ClarificationsComponent);
