import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
    Box,
} from '@mui/material';

import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';

import { Dialog } from "UI";

import { Search } from "features/pages";
import { DiseaseList } from "./DiseaseList";
import { DiseaseDetails } from "../DiseaseDetails";

import {
    selectCurrentPatientDisease,
    resetCurrentPatientDisease,
} from "features/pages/pagesSlice";

const DiseaseComponent = () => {
    const dispatch = useDispatch();

    const currentPatientDisease = useSelector(selectCurrentPatientDisease);

    const handleClosePatientDisease = () => {
        dispatch(resetCurrentPatientDisease());
    }

    console.log(currentPatientDisease);

    return <>
        <Box sx={{ width: '100%', pl: 3, pr: 3 }}>
            <Search
                // variant="parse"
                type="Disease"
                label={"Заболевания в анамнезе"}
                itemIcon={<OfflineBoltIcon sx={{ color: 'text.secondary' }} />}
                inputColor="#3977c629"
                sx={{
                    backgroundColor: "#fcfdfd",
                }}
                resultItemProps={{
                    sx: {
                        display: "inline-block",
                        "&:first-letter": {
                            textTransform: "uppercase",
                        }
                    }
                }}

            />

            <DiseaseList />

        </Box>

        <Dialog
            open={!!currentPatientDisease}
            onClose={handleClosePatientDisease}
        >
            <DiseaseDetails />
        </Dialog>
    </>;
}

export const Disease = memo(DiseaseComponent);
