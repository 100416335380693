import React, { memo, useState, useMemo, useEffect } from "react";
import { useDispatch } from "react-redux";
import { MIN_SEARCH_TEXT, MAX_SEARCH_WORDS } from "constants";

import {
    Autocomplete,
    Box,
    Typography,
    Grid,
    TextField,
} from '@mui/material';

import { debounce } from '@mui/material/utils';

import {
    sendSearch,
    addToSymptomsList,
    addToClarificationsList,
    addToPatientEditDisease,
    addToPatientEditClarificationsList,
    sendGetSymptomsFromText,
} from "features/pages/pagesSlice";


// const SEARCHTEST = "рвота, тошнота, боль в животе, боль в левом подреберье, гипертрофия миокарда правого желудочка (ГПЖ), стеноз легочного ствола подклапанный,  дефект межжелудочковой перегородки";

const SearchComponent = ({ type, resultItemProps = {}, label = "", item = {}, itemIcon = "", showLevel = false, inputColor = "", variant = "inline", sx = {} }) => {
    const dispatch = useDispatch();

    const [value, setValue] = useState(null);
    const [optionsDisabled, setOptionsDisabled] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);

    const handleKeyPress = (event) => {
        if (event.code === "Enter" || event.key === "Enter" || event.keyCode === "13") {
            if (!isHighlighted && variant === "parse") {
                dispatch(sendGetSymptomsFromText({ data: { text: inputValue } }));
                event.preventDefault();
                // event.preventDefault();
            }
        }
    }

    const fetch = useMemo(
        () =>
            debounce((request, success) => {
                dispatch(sendSearch({
                    data: {
                        ...request,
                        conceptId: item.id || null,
                        type
                    },
                    success,
                }));
            }, 400),
        [dispatch, item.id, type],
    );

    useEffect(() => {
        let active = true;


        if (inputValue === '' || inputValue.length <= MIN_SEARCH_TEXT) {
            setOptions(value ? [value] : []);
            return undefined;
        }

        const wordsCount = (inputValue.match(/\S+/g) || []).length;

        console.log(wordsCount);

        if (wordsCount > MAX_SEARCH_WORDS) {
            setOptions([]);
            return undefined;
        }

        fetch({ text: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);


    return <>
        <Autocomplete
            fullWidth
            filterSelectedOptions
            id="search1"
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.name
            }
            filterOptions={(x) => x}
            options={optionsDisabled ? [] : options}
            freeSolo={variant === "parse"}
            inputValue={inputValue}
            value={value}
            sx={{
                outline: `4px solid ${inputColor}`,
                borderRadius: "5px",
                outlineOffset: "-4px",
                ...sx
            }}
            noOptionsText={"нет результатов"}

            onHighlightChange={(_, options) => {
                setIsHighlighted(!!options?.name);
            }}
            onChange={(_, newValue) => {
                if (type === "Symptoms") {
                    dispatch(addToSymptomsList(newValue));
                } else if (type === "Details" || type === "Localization") {
                    dispatch(addToClarificationsList({ item, value: newValue, type }));
                } else if (type === "Disease") {
                    dispatch(addToPatientEditDisease(newValue));
                } else if (type === "DiseaseDetails") {
                    dispatch(addToPatientEditClarificationsList({ item, value: newValue, type }));
                }

                setInputValue("");
                setValue(null);
            }}
            onKeyDownCapture={handleKeyPress}
            onInputChange={(_, newInputValue) => {

                const regex = /(\r\n|\r|\n)/gm;
                const checkOptionsDisabled = regex.test(newInputValue);

                if (checkOptionsDisabled !== optionsDisabled) {
                    setOptionsDisabled(checkOptionsDisabled);
                }

                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    label={label}
                    fullWidth
                    multiline={variant === "parse"}
                    {...params}
                />
            )}

            renderOption={(props, option) => {
                return (
                    <li {...props} key={props.id}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 30 }}>
                                {itemIcon}
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                <Box component="span" {...resultItemProps}>
                                    {option.name}
                                </Box>
                                {showLevel && option.levelName ? <Typography variant="body2" color="text.secondary">
                                    {option.levelName}
                                </Typography> : null}
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    </>;
}

export const Search = memo(SearchComponent);
