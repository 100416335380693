import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import InputMask from "react-input-mask";

import { TransitionGroup } from 'react-transition-group';
import { checkErrors } from 'helpers';

import {
    Box,
    Button,
    ButtonGroup,
    Collapse,
    FormControl,
    InputLabel,
    InputAdornment,
    OutlinedInput,
    ToggleButtonGroup,
    ToggleButton,
    TextField as TextFieldMUI,
    Stack as StackMUI,
} from '@mui/material';


import {
    setCurrentPage,
} from "features/main/mainSlice";

import {
    selectPatient,
    selectPatientEdit,
    setPatient,
    setPatientEdit,
} from "features/pages/pagesSlice";

import { Disease } from "./components/Disease";
import { DateTime } from "./components/DateTime";
import { BirthDate } from "./components/BirthDate";

import { PaperBlock } from "./components";


// import styles from './Patient.module.css';

const BGColor = "#fcfdfd";

const TextField = ({ helperText, ...props }) => {
    return <TextFieldMUI
        fullWidth
        helperText={helperText || " "}
        inputProps={{
            sx: {
                backgroundColor: BGColor
            }
        }}
        {...props}
    />;
}

const Stack = (props) => {
    return <StackMUI
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 3, md: 4 }}
        justifyContent="space-between"
        alignItems="center"
        sx={{ m: 3 }}
        {...props}
    />;
}




const PatientComponent = () => {
    const dispatch = useDispatch();

    const patient = useSelector(selectPatient);
    const patientState = useSelector(selectPatientEdit);

    // const [patientState, setPatientState] = useState(patient);
    const [openCard, setOpenCard] = useState(false);
    const [age, setAge] = useState("");
    const [IMT, setIMT] = useState("");
    const [PPT, setPPT] = useState("");
    const [errors, setErrors] = useState({});

    const {
        lastname,
        firstname,
        patronymic,
        email,
        snils,
        policyNum,
        medcardNum,
        mainAddress,
        birthdate,
        growth,
        weight,
        sex,
    } = patientState;

    const handleClickBack = () => {
        dispatch(setCurrentPage("symptoms"));
    }

    const handleClicSave = () => {

        const formErrors = checkErrors(patientState, [
            { name: "email", type: "email" },
            // { name: "lastname", required: true },
            // { name: "firstname", required: true },
        ]);

        console.log("formErrors", formErrors);

        setErrors(formErrors);
        if (!Object.keys(formErrors).length) {

            dispatch(setPatient({
                ...patientState,
                growth: patientState.growth ? +patientState.growth : null,
                weight: patientState.weight ? +patientState.weight : null,
                trimester: patientState.trimester ? +patientState.trimester : null,
                gestation: patientState.gestation ? +patientState.gestation : null,
            }));
            dispatch(setCurrentPage("symptoms"));
        }
    }


    const handleChangeAge = (event) => {
        console.log(event);
    }

    const handleChangeDisease = (event) => {
        console.log(event);
    }

    const handleBeforeMaskedStateChange = ({ nextState }) => {
        console.log(nextState);
        let { value } = nextState;
        if (nextState.value === "0000 0000 0000 0000" || nextState.value === "000-000-000 00") {
            value = "";
        }

        return {
            ...nextState,
            value
        };

    }

    const handleClickOpenCard = () => {
        setOpenCard(!openCard);
    }

    const handleClickReset = () => {
        dispatch(setPatientEdit(patient));
    }

    const handleChangePatient = (event) => {
        let { name, value } = event.target;
        console.log("name", name);
        console.log("name", value);

        dispatch(setPatientEdit({
            ...patientState,
            [name]: value
        }));
    }

    const handleChangePatientDate = ({ name, value }) => {
        console.log("name", name);
        console.log("value", value);



        dispatch(setPatientEdit({
            ...patientState,
            [name]: value
        }));
    }

    const handleChangeSex = (event, newValue) => {
        dispatch(setPatientEdit({
            ...patientState,
            sex: newValue
        }));
    }


    useEffect(() => {
        dispatch(setPatientEdit(patient));
    }, []);

    useEffect(() => {
        let imt = "";
        let ppt = "";

        if (growth && weight) {
            // imt = Math.round((weight / ((growth / 100) ** 2)) * 100) / 100;
            imt = (weight / Math.pow(growth / 100, 2)).toFixed(2);
            ppt =
                (
                    0.007184 *
                    Math.pow(growth, 0.725) *
                    Math.pow(weight, 0.425)
                ).toFixed(2)
                ;
        }

        setIMT(imt);
        setPPT(ppt);

    }, [growth, weight]);



    return <>
        <Box sx={{
            height: "calc(100vh - 126px)",
            overflow: "auto",
            width: '100%',
            pt: 2,
        }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                }}>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{
                        mr: 2,
                    }}
                    // startIcon={<ArrowBackIcon />}
                    onClick={handleClickOpenCard}
                >
                    Паспортные данные
                </Button>
            </Box>
            <TransitionGroup>
                {openCard && <Collapse>
                    <PaperBlock>
                        <Stack>
                            <TextField
                                required
                                error={!!errors.lastname}
                                helperText={errors.lastname}
                                label="Фамилия"
                                value={lastname}
                                name="lastname"
                                onChange={handleChangePatient}
                            />
                            <TextField
                                required
                                error={!!errors.firstname}
                                helperText={errors.firstname}
                                label="Имя"
                                value={firstname}
                                name="firstname"
                                onChange={handleChangePatient}
                            />
                            <TextField
                                label="Отчество"
                                value={patronymic}
                                name="patronymic"
                                onChange={handleChangePatient}
                            />
                        </Stack>



                        <Stack>
                            <TextField
                                error={!!errors.email}
                                helperText={errors.email}
                                label="E-mail"
                                value={email}
                                name="email"
                                onChange={handleChangePatient}
                            />
                            <InputMask
                                mask="999-999-999 99"
                                alwaysShowMask
                                maskPlaceholder="0"
                                beforeMaskedStateChange={handleBeforeMaskedStateChange}
                                onChange={handleChangePatient}
                                value={snils}
                            >
                                <TextField
                                    label="СНИЛС"
                                    name="snils"
                                />
                            </InputMask>

                            <InputMask
                                mask="9999 9999 9999 9999"
                                alwaysShowMask
                                maskPlaceholder="0"
                                beforeMaskedStateChange={handleBeforeMaskedStateChange}
                                onChange={handleChangePatient}
                                value={policyNum}
                            >
                                <TextField
                                    label="Полис ОМС"
                                    name="policyNum"
                                />
                            </InputMask>
                        </Stack>


                        <Stack>
                            <TextField
                                label="Номер медицинской карты пациента"
                                value={medcardNum}
                                name="medcardNum"
                                onChange={handleChangePatient}
                            />
                            <TextField
                                label="Адрес места жительства"
                                value={mainAddress}
                                name="mainAddress"
                                onChange={handleChangePatient}
                            />
                        </Stack>
                    </PaperBlock>
                </Collapse>}
            </TransitionGroup>


            <PaperBlock>
                <Stack>
                    {/* <TextField
                        label="Возраст"
                        value={age}
                        name="age"
                        onChange={handleChangeAge}
                    /> */}
                    <BirthDate
                        label="Возраст"
                        name="birthdate"
                        value={birthdate}
                        onChange={(e, { name, value }) => this.handleChange(e, { name, value })}

                    // trans={t}
                    />

                    {/* <TextField
                        label="Дата рождения"
                        value={birthdate || ""}
                        name="birthdate"
                        onChange={handleChangePatient}
                    /> */}
                    <DateTime
                        key={"birthdate"}
                        name={"birthdate"}
                        value={birthdate}
                        title="Дата рождения"
                        onChange={handleChangePatientDate}
                    />
                    <ToggleButtonGroup
                        sx={{
                            backgroundColor: BGColor
                        }}
                        fullWidth
                        color="primary"
                        value={sex}
                        name="sex"
                        exclusive
                        onChange={handleChangeSex}
                    // sx={{ mb: "21px !important" }}
                    >
                        <ToggleButton value="m">Мужчина</ToggleButton>
                        <ToggleButton value="f">Женщина</ToggleButton>
                    </ToggleButtonGroup>

                </Stack>


            </PaperBlock>






            <PaperBlock>
                <Stack>

                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="growth">Рост</InputLabel>
                        <OutlinedInput
                            sx={{
                                backgroundColor: BGColor
                            }}
                            id="growth"
                            type={'text'}
                            value={growth || ""}
                            name="growth"
                            onChange={handleChangePatient}
                            endAdornment={
                                <InputAdornment position="end">
                                    см
                                </InputAdornment>
                            }
                            label="Рост"
                        />
                    </FormControl>

                    <FormControl fullWidth variant="outlined">
                        <InputLabel htmlFor="weight">Вес</InputLabel>
                        <OutlinedInput
                            sx={{
                                backgroundColor: BGColor
                            }}
                            id="weight"
                            type={'text'}
                            value={weight || ""}
                            name="weight"
                            onChange={handleChangePatient}
                            endAdornment={
                                <InputAdornment position="end">
                                    кг
                                </InputAdornment>
                            }
                            label="Вес"
                        />
                    </FormControl>


                    <FormControl disabled fullWidth variant="outlined">
                        <InputLabel htmlFor="IMT">ИМТ</InputLabel>
                        <OutlinedInput
                            sx={{
                                backgroundColor: BGColor
                            }}
                            id="IMT"
                            type={'text'}
                            value={IMT}
                            name="IMT"
                            onChange={handleChangePatient}
                            endAdornment={
                                <InputAdornment position="end">
                                    кг/м²
                                </InputAdornment>
                            }
                            label="ИМТ"
                        />
                    </FormControl>

                    <FormControl disabled fullWidth variant="outlined">
                        <InputLabel htmlFor="PPT">ППТ</InputLabel>
                        <OutlinedInput
                            sx={{
                                backgroundColor: BGColor
                            }}
                            id="PPT"
                            type={'text'}
                            value={PPT}
                            name="PPT"
                            onChange={handleChangePatient}
                            endAdornment={
                                <InputAdornment position="end">
                                    м²
                                </InputAdornment>
                            }
                            label="ППТ"
                        />
                    </FormControl>


                </Stack>
            </PaperBlock>






            <PaperBlock>
                <Disease />
            </PaperBlock>


        </Box >

        <ButtonGroup
            fullWidth
            size="large"
            aria-label="button group"
        >
            <Button
                disabled={false}
                onClick={handleClickBack}
            >Назад</Button>
            <Button
                disabled={false}
                onClick={handleClickReset}
            >Сбросить</Button>
            <Button
                disabled={false}
                onClick={handleClicSave}
            >Сохранить</Button>
        </ButtonGroup>
    </>;
}

export const Patient = memo(PatientComponent);
