import { SERVER } from './server';

const SERVERS = {
    prod: {
        api: "https://service.socmedica.com:9061",
        auth: "https://service.socmedica.com:9061",
    },
    dev: {
        api: "https://service.socmedica.dev:9061",
        auth: "https://service.socmedica.dev:9061",
    },
    box: {
        api: "http://ecp.box:30946",
        auth: "http://ecp.box:44380",
    },
};


function getUrls() {
    const { api: APIServer } = SERVERS[SERVER];

    return {
        root: APIServer,
        graph: {
            // GetGraphData: "https://jsonplaceholder.typicode.com/posts",
            GetGraphData: APIServer + "/GetGraph",
        },
        main: {
            ClearCache: APIServer + "/ClearCache",
            CommonSearch: APIServer + "/CommonSearch",
            SubmitSymptoms: APIServer + "/SubmitSymptoms",
            SubmitRAS: APIServer + "/SubmitRAS",
            GetSymptomsFromText: APIServer + "/GetSymptomsFromText",
        },
    }
}

export const API_URLS = getUrls();