import { API_URLS } from "constants";
import { POST } from "helpers";

export function fetchSendSearch({ data = {}, success = null }) {
  return POST({
    path: API_URLS.main.CommonSearch,
    data,
    success: (result) => {
      let searchResult = result && result.concepts && result.concepts.length > 0 ? result.concepts : [];
      success && success(searchResult);
    },
  });
}

export function fetchSendGetRAS({ data = {}, success = null }) {

  return POST({
    path: API_URLS.main.SubmitRAS,
    data,
    success: (result) => {
    //   let searchResult = result && result.concepts && result.concepts.length > 0 ? result.concepts : [];
      success && success();
    },
  });
}

export function fetchSendGetDiagnostic({ data = {}, success = null }) {
  return POST({
    path: API_URLS.main.SubmitSymptoms,
    data: {
      key: "",
      login: "",
      authkey: data.authKey,
      // guid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      patient: data.patient,
      settings: data.settings,
      questions: data.questions,
      includeGraph: false,
      symptoms: {
        formalized: data.formalizedSymptoms,
        freeText: ""
      },
      properties: null
    },
    success: () => {
      success && success();
    },
  });
}

export function fetchGetSymptomsFromText({ data = {} }) {
  return POST({
    path: API_URLS.main.GetSymptomsFromText,
    data: {
      authkey: data.authKey,
      text: data.text,
    },
  });
}
